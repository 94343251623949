import { computeIconStyle, IconStyleType } from "..";
import { Shape } from "../../../components/shapes";
import { CssVariablesType } from "../../../styles/CSSVariablesTypes";
import { Grid } from "../../blocs";
import { IconButtonIcons, IconList } from "../../icons";

type Props = {
  children: any;
  size?: number;
  icon?: IconList | JSX.Element;
  iconStyle?: IconStyleType;
  className?: string;
  iconColor?: CssVariablesType;
  shapeColor?: CssVariablesType;
  withShape?: boolean;
};

export function H1({ children, size = 20, icon, iconColor = "text-soft", shapeColor = "blue-background", iconStyle = "info-light", className = "", withShape = true }: Props) {
  let computed = computeIconStyle(iconStyle);
  const computedShapeColor = computed?.shapeColor ?? shapeColor;
  const computedIconColor = computed?.iconColor ?? iconColor;

  return (
    <Grid mediaQuery={0} template={icon ? `auto 1fr` : "1fr"} alignItems="center" className="mb-1 mt-4">
      {icon && withShape
        && <Shape form="circle" color={computedShapeColor}>
          {typeof icon == "string" &&
            <IconButtonIcons icon={icon} size={size} color={computedIconColor} />}
          {typeof icon !== "string" && icon}
        </Shape>
      }

      {icon && !withShape && <>
        {typeof icon == "string" &&
          <IconButtonIcons icon={icon} size={size * 2} color={computedIconColor} />}
        {typeof icon !== "string" && icon}
      </>}
      <h1 className={"mt-0 mb-0 " + className}>{children}</h1>
    </Grid>
  );
}
