import React from "react";
import { BsQuestionLg } from "../../../react-icons/bs";
import { FaGear } from "../../../react-icons/fa6";
import { IoMail } from "../../../react-icons/io5";
import { LuLightbulb } from "../../../react-icons/lu";
import { MdWork } from "../../../react-icons/md";

type Props = {
  size?: number;
  color?: string;
  type?: "technical" | "commercial" | "suggestion" | "message";
};

export function ReportTypeIcon({ type, size = 25, color = "var(--color-text)" }: Props) {
  return (
    <>
      {type === "technical" && <FaGear className="icon" size={size} color={color} />}
      {type === "commercial" && <MdWork className="icon" size={size} color={color} />}
      {type === "suggestion" && <LuLightbulb className="icon" size={size} color={color} />}
      {type === "message" && <IoMail className="icon" size={size} color={color} />}
      {type != "suggestion" && type != "commercial" && type != "technical" && type != "message" && <BsQuestionLg className="icon" size={size} color={color} />}
    </>
  );
}
