import classNames from "classnames";
import { QuestionExchangeTypeType } from "../../../../utilities/redux/types";
import { BsArrowRight, BsBuildings } from "../../../react-icons/bs";
import { HiOutlineBuildingLibrary } from "../../../react-icons/hi2";
import { PiMicrosoftWordLogoFill } from "../../../react-icons/pi";
import { Grid } from "../../blocs";
import { Tooltip } from "../../infos";

type Props = {
  type: QuestionExchangeTypeType;
  legend?: boolean;
  width?: number;
  iconSize?: number;
  className?: string;
};

export function QuestionExchangeIcon({ type, legend = false, width, iconSize = 25, className }: Props) {
  return (
    <div
      className={classNames("question-exchange-icon br-10", className)}
      style={{
        maxWidth: width || "",
        padding: `${(iconSize * 3) / 4}px`,
      }}
    >
      <Tooltip legend={legend ? type.title : ""} position="bottom">
        <Grid mediaQuery={0} template="1fr 1fr 1fr" alignItems="center" space={5}>
          {type?.action === "expert_sendQuestion" && (
            <>
              <HiOutlineBuildingLibrary size={iconSize} color={"var(--color-active)"} />
              <BsArrowRight size={iconSize * 0.85} color={"var(--color-text)"} />
              <BsBuildings size={iconSize} color={"var(--color-secondary)"} />
            </>
          )}

          {type?.action === "company_sendBackQuestion" && (
            <>
              <BsBuildings size={iconSize} color={"var(--color-secondary)"} />
              <BsArrowRight size={iconSize * 0.85} color={"var(--color-text)"} />
              <HiOutlineBuildingLibrary size={iconSize} color={"var(--color-active)"} />
            </>
          )}

          {type?.action === "expert_sendQuestion_withTextFile" && (
            <div className="row j-center">
              <PiMicrosoftWordLogoFill size={iconSize} color={"var(--color-active)"} />
            </div>
          )}
        </Grid>
      </Tooltip>
    </div>
  );
}
