import { store } from "@/redux/store";
import { addToast } from "@/redux/slices/ToastSlice";
import axios from "axios";

export const baseURL = process.env.NEXT_PUBLIC_API_URL;

export const expertFetcher = axios.create({
  baseURL,
  withCredentials: true,
  headers: { "X-interface": "expert" },
});

expertFetcher.interceptors.request.use(
  function (config) {
    if (store.getState()?.accountingFirm?.accountingFirm?.access?.blockedAt) {
      if (
        new Date() >
        new Date(store.getState().accountingFirm.accountingFirm?.access.blockedAt)
      ) {
        const currentUrl = window.location.href;

        if (
          currentUrl.includes("/dashboard/gestion-cabinet/collaborateurs") ||
          currentUrl.includes(
            "/dashboard/gestion-cabinet/mes-informations-personnelles"
          ) ||
          currentUrl.includes(
            "/dashboard/gestion-cabinet/mes-preferences-de-notifications"
          ) ||
          currentUrl.includes("/dashboard/dossiers") ||
          currentUrl === "/dashboard" ||
          currentUrl === "/dashboard/gestion-cabinet"
        ) {
          // window.location.href = "/dashboard/gestion-cabinet/facturation/regularisation";
          return Promise.reject("Accès interdit");
        }
      }
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

expertFetcher.interceptors.response.use(
  (response) => {
    // n’importe quel code de réponse HTTP dans la plage 2xx activera cette
    // fonction;
    // faire quelque chose avec les données de la réponse

    // pour ne pas avoir de toast si on récupère un csv
    if (response?.headers["content-type"].includes("csv") || response?.headers["content-type"].includes("xml")) {
      return response;
    }
    if (typeof response.data === "string" && response.data.length > 0) {
      store.dispatch(addToast({ legend: response.data, type: "good" }));
    }
    if (response.data.message && typeof response.data?.message === "string" && response.data?.message.length > 0) {
      store.dispatch(addToast({ legend: response.data.message, type: "good" }));
    }

    return response;
  },
  (error) => {
    if (error.code === "ERR_CANCELED") return;

    console.log(error);
    if (error?.response?.status === 403) {
      window.location.href = "/error/403";
      return Promise.reject(error);
    }

    if (error?.response?.status === 401) {
      window.location.href = "/auth/deconnexion";
      return;
    }

    // Cabinet supprimé
    if (error?.response?.status === 423) {
      store.dispatch(
        addToast({ legend: error.response?.data?.detail, type: "fail" })
      );
      window.location.href = "/cabinet-supprime";
      return Promise.reject(error);
    }

    // Cabinet supprimé
    if (error?.response?.status === 402) {
      store.dispatch(
        addToast({ legend: error.response?.data?.detail, type: "fail" })
      );
      window.location.href =
        "/dashboard/gestion-cabinet/facturation/regularisation";
      return Promise.reject(error);
    }

    if (error?.response?.status === 406 || error?.response?.status === 204) {
      if (typeof error?.response?.data === "string") {
        store.dispatch(
          addToast({ legend: error.response?.data?.detail, type: "warning" })
        );
        return Promise.reject(error);
      } else {
        store.dispatch(
          addToast({
            legend:
              "Impossible de télécharger des données en lien avec votre demande",
            type: "warning",
          })
        );
      }
    }

    if (error?.response?.status === 400 && error?.response?.data) {
      if (typeof error?.response?.data === "string") {
        store.dispatch(addToast({ legend: error.response.data, type: "fail" }));
        return Promise.reject(error);
      }
      if (typeof error?.response?.data?.detail === "string") {
        store.dispatch(
          addToast({ legend: error.response.data?.detail, type: "fail" })
        );
        return Promise.reject(error);
      }
      if (typeof error?.response?.data?.message === "string") {
        store.dispatch(addToast({ legend: error?.response?.data?.message, type: "fail" }));
        return Promise.reject(error);
      }
    }


    if (error?.response?.status === 404) {
      store.dispatch(
        addToast({
          legend: "La donnée n'a pas été trouvée",
          type: "warning",
        })
      );
      return Promise.reject(error);
    }

    if (error?.response?.status === 500) {
      if (typeof error?.response?.data?.detail === "string") {
        store.dispatch(
          addToast({
            legend: error?.response?.data?.detail,
            type: "fail",
          })
        );
      } else {
        store.dispatch(
          addToast({
            legend: "Problème de connexion, veuillez réessayer plus tard",
            type: "fail",
          })
        );
      }
    }

    return Promise.reject(error);
  }
);
