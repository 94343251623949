import classNames from "classnames";
import { HTMLAttributes, useState } from "react";
import { CssVariablesType } from "../../../styles/CSSVariablesTypes";
import { IconButtonIcons, IconList } from "../../icons";
import { Tooltip } from "../../infos";
import { CircleLoader } from "../../loaders";
import { ClassnameTypeType } from "../button/Button";

export type formType = "square" | "circle";
interface Props extends HTMLAttributes<HTMLButtonElement> {
  icon: IconList;
  type?: ClassnameTypeType;
  backgroundColor?: CssVariablesType;
  iconColor?: CssVariablesType;
  cb?: (e?: React.MouseEvent<HTMLButtonElement>) => void | { payload: any; type: `${string}/${string}`; } | Promise<any>;
  form?: formType;
  size?: number;
  tooltip?: string;
  tooltipPosition?: "bottom" | "left" | "top" | "right";
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

export function IconButton({
  icon,
  form = "square",
  size = 18,
  type = "gray-light",
  backgroundColor,
  iconColor,
  tooltip = "",
  tooltipPosition = "bottom",
  cb,
  className,
  disabled = false,
  ...props
}: Props) {
  const [isLoading, setIsLoading] = useState(props?.isLoading ?? false);

  async function onClick(e) {
    setIsLoading(true);
    cb && await cb(e);
    setIsLoading(false);
  }

  return (
    <button
      {...props}
      role={!cb ? "div" : "button"}
      aria-hidden={props["aria-hidden"] ?? cb === undefined}
      tabIndex={(props.tabIndex ?? cb != undefined) ? 0 : -1}
      aria-label={props["aria-label"] ?? tooltip}
      className={classNames("icon-button", type, form, className, { clickable: cb && !(disabled || (props?.isLoading ?? isLoading)) }, { disabled: !cb || disabled || (props?.isLoading ?? isLoading) })}
      onClick={
        cb && !(props?.isLoading ?? isLoading) && disabled == false
          ? (e) => {
            e.stopPropagation();
            e.preventDefault();
            onClick(e);
          }
          : (e) => {
            e.stopPropagation();
            e.preventDefault();
          }
      }
      style={{
        borderRadius: form === "square" ? `calc(${size}px / 3)` : "50%",
        padding: form === "square" ? `calc(2 * ${size}px / 5)` : `calc(${size}px / 2)`,
        aspectRatio: 1,
        maxHeight: size + "20px",
        backgroundColor: backgroundColor && `var(--color-${backgroundColor}`,
        ...props.style,
      }}
    >
      <Tooltip legend={tooltip} position={tooltipPosition}>
        {(props?.isLoading ?? isLoading) && <CircleLoader strokeWidth={7} width={size + "px"} color={`var(--color-${iconColor})`} />}

        {icon && !(props?.isLoading ?? isLoading) && <IconButtonIcons icon={icon} size={size} color={iconColor} />}
      </Tooltip>
    </button>
  );
}
