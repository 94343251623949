import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { UserType } from "utilities/redux/types";
import { ReleaseItemType } from "utilities/types/StaticTypes";

// declaring the types for our state
export type userState = {
  user: UserType;
  token: any;
  picture: any;
  releasesToCommunicate: ReleaseItemType[];
  newReleaseModalIsOpen: boolean;
};

const initialState: userState = {
  user: null,
  token: "",
  picture: null,
  releasesToCommunicate: [],
  newReleaseModalIsOpen: false,
};

export const userSlice: Slice<userState> = createSlice({
  name: "userData",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    storeUser: (state, action: PayloadAction<UserType>) => {
      state.user = action.payload;
    },
    storeReleasesToCommunicate: (state, action: PayloadAction<ReleaseItemType[]>) => {
      state.releasesToCommunicate = action.payload;
    },
    setNewReleaseIsOpen: (state, action: PayloadAction<boolean>) => {
      state.newReleaseModalIsOpen = action.payload;
    },

    changeInformationsUser: (
      state,
      action: PayloadAction<{
        firstName: string;
        lastName: string;
        takeOver: boolean;
      }>,
    ) => {
      state.user.firstName = action.payload.firstName;
      state.user.lastName = action.payload.lastName;
      state.user.userConnectionInformations.isOkWithTakeOver = action.payload.takeOver;
    },
    updatePicture: (state, action: PayloadAction<any>) => {
      state.picture = action.payload;
    },
    storeToken: (state, action: PayloadAction) => {
      state.token = action.payload;
    },

    logout: (state, action: PayloadAction) => {
      Object.assign(state, initialState);
    },
  },
});
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
  storeUser,
  changeInformationsUser,
  logout,
  storeToken,
  updatePicture,
  storeReleasesToCommunicate,

  setNewReleaseIsOpen,
} = userSlice.actions;

// calling the above actions would be useless if we could not access the data in the state. So, we use something called a selector which allows us to select a value from the state.
// export const selectCount = (state: RootState) => state.counter.value;

// exporting the reducer here, as we need to add this to the store
export default userSlice.reducer;
