export const formatDateToYYYYMMDD = (date: Date, withHour: boolean = false) => {
  const yyyy = String(date.getFullYear()).padStart(4, "0");
  const MM = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");

  if (withHour) {
    const hh = String(date.getHours()).padStart(2, "0");
    const mm = String(date.getMinutes()).padStart(2, "0");

    return `${yyyy}-${MM}-${dd}T${hh}:${mm}`;
  } else {
    return `${yyyy}-${MM}-${dd}`;
  }
};

export function dateToISOString(date: Date | string) {
  if (date === undefined) return undefined;
  if (date === null || date === "") return new Date().toISOString().split("T")[0];

  const validDate = new Date(date);

  const isValidDate = Date.parse(validDate.toString());
  if (isNaN(isValidDate)) {
    return undefined;
  }

  const offset = Math.floor(validDate.getTimezoneOffset() / 60);
  const arr = new Date(validDate.getFullYear(), validDate.getMonth(), validDate.getDate(), validDate.getHours() - offset).toISOString().split("T");

  return arr[0];
}

export function addMonth(date: string, months: number) {
  let dateCopy = new Date(date);
  dateCopy.setMonth(dateCopy.getMonth() + months);

  return dateCopy.toISOString();
}

export function dateDeletionCompany(date: string) {
  let dateCopy = new Date(date);

  dateCopy.setDate(1);
  const result = addMonth(dateCopy.toISOString(), 1);

  return parseDate(result);
}

export function ISOtoString(dateIso: string) {
  const dateArray = dateIso.split(" ");
  let dateString = new Date(dateArray[0]);
  return dateString;
}

export const getCurrentDateString = (): string => new Date(Date.now()).toISOString();

export const getDateString = (date: string) => new Date(date).toISOString();

export const getMonth = (str?: string): number => {
  const date = str ? new Date(str) : new Date();

  return date.getMonth() + 1;
};

export const getYear = (str?: string): number => {
  const date = str ? new Date(str) : new Date();

  return date.getFullYear();
};

export function lastMonthsDay() {
  const today = new Date().setDate(0);
  return dateToISOString(new Date(today));
}
export function parseDate(date: string | Date, showDate: boolean = true, showHour: boolean = false): string | "Pas de date" {
  const hourOptions = {
    hour: "numeric",
    minute: "numeric",
  };
  const dateOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };

  const options: any = {
    ...(showHour && hourOptions),
    ...(showDate && dateOptions),
    /// - 2h (UTC0)
    timeZone: "Europe/Paris",
  };

  let tempDate: Date = typeof date == "string" ? new Date(date) : date;
  if (tempDate instanceof Date) {
    const formattedDate = new Intl.DateTimeFormat("fr-FR", options)?.format(tempDate);
    return formattedDate;
  } else {
    return "Pas de date";
  }
}

const week = ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"];
const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

export function dateInFrench(dateIso: string) {
  const date = ISOtoString(dateIso);
  return `${week[date.getDay()]} ${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
}

export function monthInFrench(month: string | number) {
  const monthNumber = typeof month === "string" ? parseInt(month) : month;
  return months[monthNumber];
}

export function diffDays(input1: string | null, input2?: string | null): number {
  let date1 = input1 ? new Date(input1) : new Date();
  let date2 = input2 ? new Date(input2) : new Date();

  let millisecondsDiff = date1.getTime() - date2.getTime();
  let daysDiff = Math.ceil(millisecondsDiff / (1000 * 3600 * 24));

  return daysDiff;
}

export function diffDaysFromDates(input1: Date, input2: Date): number {
  let millisecondsDiff = input2.getTime() - input1.getTime();

  let daysDiff = Math.ceil(millisecondsDiff / (1000 * 3600 * 24));
  return daysDiff;
}

export const generateRandomDate = (from: Date, to: Date) => {
  return new Date(from.getTime() + Math.random() * (to.getTime() - from.getTime()));
};

export const getDaysArray = function (start: string, end: string) {
  for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
};

export const getDateAsPhp = (date: Date) => {
  let year = date.getFullYear();
  // Notez que getMonth() renvoie les mois de 0 à 11, donc ajoutez 1 pour obtenir le mois réel
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  // Concaténez les éléments pour obtenir le format "yyyy-mm-dd"
  let formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export function getFirsyDayInMonth(str: string) {
  const date = new Date(str);

  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getLastDayInMonth(str: string) {
  const date = new Date(str);

  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function formatDate(date: string | Date, format: "total" | "year" | "month" | "day" | "hour" | "minute" = "total") {
  const tempDate: Date = typeof date == "string" ? new Date(date.replace("T", " ")) : date;

  switch (format) {
    case "total":
      return tempDate.toLocaleString("fr-FR", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    case "year":
      return tempDate.toLocaleString("fr-FR", {
        month: "long",
        year: "numeric",
      });
    case "month":
      return tempDate.toLocaleString("fr-FR", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    case "day":
      const formattedHour = tempDate.toLocaleString("fr-FR", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
      });
      return formattedHour;
    case "hour":
      const formattedMinute = tempDate.toLocaleString("fr-FR", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
      return formattedMinute;
    case "minute":
      const formattedSecond = tempDate.toLocaleString("fr-FR", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
      return formattedSecond;
  }
}
