"use client";
import { useEffect, useState } from "react";
import { QuestionActionActionType } from "../../../../utilities/redux/types";
import { AiFillCheckCircle } from "../../../react-icons/ai";
import { BsArchiveFill } from "../../../react-icons/bs";
import { FaArrowLeft, FaArrowRight } from "../../../react-icons/fa";
import { GiCancel } from "../../../react-icons/gi";
import { IoMdAddCircle } from "../../../react-icons/io";
import { MdCancel, MdModeEditOutline, MdOutlineSettingsBackupRestore } from "../../../react-icons/md";
import { RiCloseCircleFill } from "../../../react-icons/ri";
import { TbCursorText } from "../../../react-icons/tb";
import { Shape } from "../../shapes";

type Props = {
  type: QuestionActionActionType;
  size?: number;
  colorShape?: any;
  sizeShape?: number;
  formShape?: "square" | "circle";
};

export function QuestionActionTypeIcon({ type, size = 15, colorShape = "gray1", formShape = "square", sizeShape = 30 }: Props) {
  const [backgroundColor, setBackgroundColor] = useState("gray1");

  const computeColor = () => {
    if (type === "expert_create" || type === "algo_create") {
      setBackgroundColor("infoBackground");
      return;
    }
    if (type === "expert_updateQuestion" || type === "expert_unvalidate") {
      setBackgroundColor("warningBackground");
      return;
    }
    if (type === "expert_validate") {
      setBackgroundColor("successBackground");
      return;
    }
    if (type === "expert_archive") {
      setBackgroundColor("errorBackground");
      return;
    }
    if (type === "expert_send" || type === "company_sent") {
      setBackgroundColor("secondary-background");
      return;
    }
    if (type === "expert_restore") {
      setBackgroundColor("infoBackground");
      return;
    }

    setBackgroundColor(colorShape);
  };

  useEffect(() => {
    computeColor();
  }, [type]);

  return (
    <>
      <Shape color={backgroundColor as any} form={formShape} size={sizeShape || size + 5}>
        {(type === "expert_create" || type === "algo_create") && <IoMdAddCircle size={size} color={"var(--color-info)"} />}
        {type === "expert_updateQuestion" && <MdModeEditOutline size={size} color={"var(--color-warning)"} />}
        {type === "expert_validate" && <AiFillCheckCircle size={size} color={"var(--color-success)"} />}
        {type === "expert_unvalidate" && <RiCloseCircleFill size={size} color={"var(--color-warning)"} />}
        {type === "expert_send" && <FaArrowRight size={size} color={"var(--color-secondary)"} />}
        {type === "expert_archive" && <BsArchiveFill size={size - 4} color={"var(--color-error)"} />}
        {type === "expert_restore" && <MdOutlineSettingsBackupRestore size={size} color={"var(--color-info)"} />}
        {type === "expert_processed" && <AiFillCheckCircle size={size} color={"var(--color-success)"} />}
        {type === "expert_unprocessed" && <GiCancel size={size} color={"var(--color-error)"} />}
        {type === "expert_deny" && <MdCancel size={size} color={"var(--color-error)"} />}
        {type === "expert_undeny" && <AiFillCheckCircle size={size} color={"var(--color-info)"} />}

        {type === "company_answer" && <TbCursorText size={size} color={"var(--color-secondary)"} />}
        {type === "company_sent" && <FaArrowLeft size={size} color={"var(--color-secondary)"} />}
        {type === "company_complete" && <TbCursorText size={size} color={"var(--color-info)"} />}
      </Shape>
    </>
  );
}
