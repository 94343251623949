import { BsFillTrash3Fill } from "react-icons/bs";
import { IoAddCircle } from "react-icons/io5";
import { MdModeEditOutline } from "react-icons/md";
import { BtnContainer, Button, ClassnameTypeType } from "../buttons";
import { Shape } from "../shapes";
import { Modal } from "./Modal";
import { CssVariablesType } from "../../styles/CSSVariablesTypes";
import { IconList } from "../icons";
import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";

type ModalConfirmationTypeType = "delete" | "edit" | "create";

type Props = {
  showModal: boolean;
  setShowModal: (arg: boolean) => void;
  type?: ModalConfirmationTypeType;
  cb: () => void | Promise<any>;
  customLegend?: string;
  customTitle?: string;
  customIcon?: JSX.Element;
  iconSize?: number;
};

export const ModalConfirmation = ({ showModal, setShowModal, cb, type = "delete", customLegend = null, customTitle = null, customIcon = null, iconSize = 80 }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  function getStyle(): {
    shapeColor: CssVariablesType,
    iconColor: CssVariablesType;
    buttonIcon: IconList;
    buttonType: ClassnameTypeType;
  } {
    switch (type) {
      case "delete":
        return { shapeColor: "error", iconColor: "white", buttonIcon: "trash", buttonType: "error" };
      case "edit":
        return { shapeColor: "infoBackground", iconColor: "info", buttonIcon: "edit", buttonType: "info" };
      case "create":
        return { shapeColor: "infoBackground", iconColor: "info", buttonIcon: "add", buttonType: "info" };

      default:
        return { shapeColor: "infoBackground", iconColor: "info", buttonIcon: "check", buttonType: "primary" };
    }
  };

  async function handleClick() {
    setIsLoading(true);
    const result: AxiosError | AxiosResponse | null = cb && await cb();
    setIsLoading(false);

    if (result && result?.status === 200) {
      setShowModal(false);
    }
  }

  return (
    <Modal showModal={showModal} setShowModal={isLoading ? undefined : setShowModal} maxWidth={600}>
      <div className="column j-center a-center">
        <Shape size={iconSize * 2} className="m-auto mt-2 mb-2" color={getStyle().shapeColor}>
          {type == "delete" && !customIcon && <BsFillTrash3Fill size={iconSize} color={getStyle().iconColor} />}
          {type == "edit" && !customIcon && <MdModeEditOutline size={iconSize} color={getStyle().iconColor} />}
          {type == "create" && !customIcon && <IoAddCircle size={iconSize} color={getStyle().iconColor} />}
          {customIcon}
        </Shape>

        <h2 className="text-center mt-1">
          {type == "delete" && !customTitle && "Êtes vous sûr de vouloir supprimer cet élément ?"}
          {type == "edit" && !customTitle && "Êtes vous sûr de vouloir éditer cet élément ?"}
          {type == "create" && !customTitle && "Êtes vous sûr de vouloir créer cet élément ?"}
          {customTitle}
        </h2>
        <p className="text-center mb-2">{customLegend ?? "Cette action est irréversible, souhaitez-vous continuer ?"}</p>

        <BtnContainer className="mt-2">
          <Button disabled={isLoading} type="transparent" icon={"close"} cb={() => setShowModal(false)}>
            Annuler
          </Button>
          <Button type={getStyle()?.buttonType} cb={handleClick} icon={getStyle().buttonIcon}>
            Confirmer
          </Button>
        </BtnContainer>
      </div>
    </Modal>
  );
};
