import { FaAddressCard, FaArchive, FaBalanceScale, FaBed, FaBroom, FaClock, FaMoneyBillWaveAlt, FaTruck, FaUserFriends } from "../../../react-icons/fa";

import { BsCheckLg, BsFillBoxSeamFill, BsFillBuildingsFill } from "../../../react-icons/bs";
import { GoMortarBoard } from "../../../react-icons/go";
import { IoCloseOutline, IoReceipt } from "../../../react-icons/io5";
import { MdQuestionMark } from "../../../react-icons/md";
import { TbMoneybag } from "../../../react-icons/tb";

import classNames from "classnames";
import { HTMLAttributes } from "react";
import { CategoryIconType } from "../../../../utilities/redux/types";
import { ImEarth } from "../../../react-icons/im";

interface Props extends HTMLAttributes<HTMLDivElement> {
  iconName: CategoryIconType;
  size?: number;
  color?: string;
  disabled?: boolean;
  form?: "rectangle" | "circle";
}

export function CategoryIcon({ iconName, size = 20, color = "var(--color-text)", disabled = false, form = "rectangle", ...props }: Props) {
  return (
    <div
      role={props.onClick ? "button" : "div"}
      {...props}
      tabIndex={(props.tabIndex ?? props.onClick) ? 0 : -1}
      className={classNames("icon m-0", props.className)}
      style={{ display: "flex", borderRadius: form === "circle" ? "500px" : "30px" }}
    >
      {iconName === "FaMoneyBillWaveAlt" && <FaMoneyBillWaveAlt size={size} color={color} style={{ fill: color }} />}
      {iconName === "FaUserFriends" && <FaUserFriends size={size} color={color} style={{ fill: color }} />}
      {iconName === "FaTruck" && <FaTruck size={size} color={color} style={{ fill: color }} />}
      {iconName === "FaBalanceScale" && <FaBalanceScale size={size} color={color} style={{ fill: color }} />}
      {iconName === "FaClock" && <FaClock size={size} color={color} style={{ fill: color }} />}
      {iconName === "IoReceipt" && <IoReceipt size={size} color={color} style={{ fill: color }} />}
      {iconName === "FaBoxOpen" && <BsFillBoxSeamFill size={size} color={color} style={{ fill: color }} />}
      {iconName === "TbMoneybag" && <TbMoneybag size={size} color={color} fill={color} stroke={color} style={{ fill: color, stroke: color }} />}
      {iconName === "GoMortarBoard" && <GoMortarBoard size={size} color={color} style={{ fill: color }} />}
      {iconName === "MdQuestionMark" && <MdQuestionMark size={size} color={color} style={{ fill: color }} />}
      {iconName === "BsCheckLg" && <BsCheckLg size={size} color={color} style={{ fill: color }} />}
      {iconName === "IoCloseOutline" && <IoCloseOutline size={size} color={color} style={{ fill: color }} />}

      {iconName === "FaAddressBook" && <FaAddressCard size={size} color={color} />}
      {iconName === "FaBuildingColumns" && <BsFillBuildingsFill size={size} color={color} />}
      {iconName === "FaEarthEurope" && <ImEarth size={size} color={color} />}
      {iconName === "FaArchive" && <FaArchive size={size} color={color} />}
      {iconName === "FaBed" && <FaBed size={size} color={color} />}
      {iconName === "FaBroom" && <FaBroom size={size} color={color} />}
    </div>
  );
}
