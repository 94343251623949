import { AxiosInstance } from "axios";
import { useEffect, useRef } from "react";
import { NotificationType, UserType } from "../../../utilities/redux/types";
import { FaBell } from "../../react-icons/fa";
import NotificationWindow from "./NotificationWindow";

type Props = {
  fetcher: AxiosInstance;

  notificationsPreview: NotificationType[];
  notificationsCount: number;
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  storeNotifications: (notifs: NotificationType[]) => void;
  storePreviewNotifications: (notifs: NotificationType[]) => void;
  storeNotificationsCount: (count: number) => void;
  router: any;
  user: UserType;
  mediaQuery?: boolean;
};

export default function NotificationBtn({
  fetcher,
  notificationsCount,
  isOpen,
  notificationsPreview,
  setIsOpen,
  storeNotifications,
  storePreviewNotifications,
  storeNotificationsCount,
  router,
  user,
  mediaQuery = false,
}: Props) {
  const btnRef = useRef(null);

  const closeMenu = (e) => {
    if (!mediaQuery) {
      const notifWindow = document?.querySelector("#notification-window");

      if (notifWindow && !notifWindow.contains(e?.target) && !btnRef?.current?.contains(e.target)) {
        setIsOpen(false);
      }
    }
  };

  // const handleMercureUpdateCount = (eventSource: EventSource) => {
  // eventSource.onopen = (event) => {
  //   console.log("OPEN MERCURE");
  // };
  // eventSource.onerror = (event) => {
  //   console.log("OPEN ERROR");
  // };
  // eventSource.onmessage = (event) => {
  //   console.log("MESSAGE");
  //   const newMessage = JSON.parse(event.data);
  //   if (newMessage) {
  //     storeNotificationsCount(newMessage);
  //   }
  // };
  // };

  useEffect(() => {
    window.addEventListener("mousedown", closeMenu);

    // const mercureURL = process.env.NEXT_PUBLIC_MERCURE_URL;
    // let eventSource = null;

    // if (user && mercureURL) {
    //   const url = new URL(mercureURL);
    //   url.searchParams.append("topic", `syndemos/user/notification_count/${user.email}`);

    //   eventSource = new EventSource(url, {
    //     withCredentials: true,
    //   });
    //   // Gestionnaire d'événements pour les mises à jour
    //   handleMercureUpdateCount(eventSource);
    // }

    return () => {
      // eventSource?.close();
      window.removeEventListener("mousedown", closeMenu);
    };
  }, [user]);

  return (
    <div style={{ position: "relative" }} id="notification-btn-container" ref={btnRef}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "55px",
          height: "55px",
          borderRadius: "50%",
        }}
        className="circle clickable"
        id="notification-btn"
        onClick={() => {
          setIsOpen(!isOpen);

          if (mediaQuery) {
            router.push("/dashboard/mes-notifications");
          }
        }}
      >
        <FaBell color={"var(--color-gray14)"} />
        {notificationsCount > 0 && <div id="number">{notificationsCount}</div>}
      </div>
      {isOpen == true && (
        <NotificationWindow
          router={router}
          fetcher={fetcher}
          notificationsCount={notificationsCount}
          setNotificationWindowIsOpen={setIsOpen}
          notificationsPreview={notificationsPreview}
          storeNotifications={storeNotifications}
          storeNotificationsCount={storeNotificationsCount}
          storePreviewNotifications={storePreviewNotifications}
        />
      )}
    </div>
  );
}
