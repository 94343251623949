import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { CompanyType, NotificationCompanyType, NotificationType, NotificationTypeType, NotificationUserPreferenceType } from "utilities/redux/types";

// declaring the types for our state
export type NotificationUserState = {
  notificationUserPreferences: NotificationUserPreferenceType[];
  preferenceTypeSelected: NotificationTypeType;
  notificationTopicTypes: NotificationTypeType[];
  notifications: NotificationType[];
  previewNotifications: NotificationType[];
  notificationWindowIsOpen: boolean;
  notificationsCount: number;
};

const initialState: NotificationUserState = {
  notificationUserPreferences: [],
  notifications: [],
  previewNotifications: [],
  notificationTopicTypes: [],
  notificationWindowIsOpen: false,
  notificationsCount: 0,
  preferenceTypeSelected: null,
};

export const notificationUserSlice: Slice<NotificationUserState> = createSlice({
  name: "notificationCompanyData",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    storePreviewNotifications: (state, action: PayloadAction<NotificationType[]>) => {
      state.previewNotifications = action.payload;
    },
    storeNotifications: (state, action: PayloadAction<NotificationType[]>) => {
      state.notifications = action.payload;
    },
    storeNotificationsCount: (state, action: PayloadAction<number>) => {
      state.notificationsCount = action.payload;
    },
    setNotificationWindowIsOpen: (state, action: PayloadAction<boolean>) => {
      state.notificationWindowIsOpen = action.payload;
    },
    setNotificationTopicTypes: (state, action: PayloadAction<NotificationTypeType[]>) => {
      state.notificationTopicTypes = action.payload;
    },
    storePreferences: (state, action: PayloadAction<NotificationUserPreferenceType[]>) => {
      state.notificationUserPreferences = action.payload;
    },
    setPreferenceTypeSelected: (state, action: PayloadAction<NotificationUserPreferenceType>) => {
      state.preferenceTypeSelected = action.payload;
    },
  },
});

// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
  setPreferenceTypeSelected,
  preferenceTypeSelected,
  storeNotifications,
  setNotificationWindowIsOpen,
  setNotificationTopicTypes,
  storePreferences,
  storeNotificationsCount,
  storePreviewNotifications,
} = notificationUserSlice.actions;

export default notificationUserSlice.reducer;
