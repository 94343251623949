"use client";

import { HTMLAttributes, useEffect, useRef, useState } from "react";
import { CompanyPlaceholder } from "./CompanyPlaceholder";
import { serverURL } from "../../../../utilities/config/server";
import classNames from "classnames";
import { MdModeEditOutline } from "../../../react-icons/md";

interface Props extends HTMLAttributes<HTMLDivElement> {
  slug?: string;
  alt?: string;
  size?: number;
  className?: string;
  type?: "thumbnail" | "cover";
  app: "expert" | "company" | "admin";
  handleModify?: (e: any) => void;
  preview?: string;
  isPictureDefined?: boolean;
  updatedAt?: string,
};

export function CompanyPicture({
  slug = "",
  size = 30,
  className = "",
  type = "thumbnail",
  app,
  preview,
  handleModify,
  isPictureDefined = false,
  updatedAt,
  children,
  ...props
}: Props) {
  const [notFound, setNotFound] = useState(false);
  const inputRef: any = useRef(null);

  useEffect(() => {
    preview && setNotFound(false);
  }, [preview]);

  return (
    <div
      {...props}
      className={classNames("picture company-picture ", className, { clickable: handleModify })}
      style={{ ...props?.style, width: size + "px", height: size + "px" }}
      onClick={handleModify ? () => inputRef?.current?.click() : () => { }}
    >
      <input
        ref={inputRef}
        hidden
        type="file"
        accept="image/*"
        onChange={handleModify ? (e: any) => handleModify(e?.target?.files) : () => { }}
      />

      {/*/////////////// Clickable ///////////////////////////////////*/}

      <div className="img-container">
        {slug && handleModify && (
          <>
            <div className="cache" />
            <MdModeEditOutline className="icon modify" size={40} />
          </>
        )}

        {notFound === false
          && slug
          && (isPictureDefined == true || preview)
          && <img
            // fill
            src={
              preview ? preview : `${serverURL}/api/${app}/company/${type}/${slug}?time=${updatedAt ? Date.parse(new Date(updatedAt)?.toISOString()) : null}`
            }
            aria-hidden
            onError={() => {
              setNotFound(true);
              return;
            }}
          />
        }

        {(isPictureDefined == false || isPictureDefined == null || notFound == true || !slug) && !preview && <CompanyPlaceholder />}
      </div>

      {children}
    </div>
  );
}
