import { UserType } from "../../../../utilities/redux/types";

type Props = {
  user?: Partial<UserType>;
  size?: number;
};

export function UserPlaceholder({ user, size }: Props) {
  return (
    <>
      {user?.firstName && user?.lastName ? (
        <p className="m-0 text-placeholder" style={{ fontSize: size ? `calc(${size} * 0.021rem)` : "0.95rem", letterSpacing: size ? `calc(${size} * -0.04px)` : "-1px" }}>
          {user?.firstName[0]} {user?.lastName[0]}
        </p>
      ) : (
        <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" style={{ width: "80%" }} className="placeholder">
          <circle className="cls-1" cx="102.3" cy="72.3" r="30.36" />
          <path className="cls-1" d="m45,168.96c-1.37,0-2.46-1.14-2.4-2.51,1.31-31.84,27.54-57.25,59.7-57.25s58.39,25.41,59.7,57.25c.06,1.37-1.04,2.51-2.4,2.51H45Z" />
        </svg>
      )}
    </>
  );
}
