import { Grid } from "../../../components/blocs";
import UserTeamIconItem from "./UserTeamIconItem";

type Props = {
  size?: number;
  color?: string;
  setter?: (arg: string) => void;
  selected?: string;
};

export const userListIcons = ["dollar", "bug", "graduate", "admins", "users"];

const UserTeamIconList = ({ size = 30, color = "var(--color-gray2)", setter, selected }: Props) => {
  return (
    <div className="tile gray-light thin">
      <Grid template="repeat(auto-fit, 55px)">
        {userListIcons?.map((icon, key) => {
          return <UserTeamIconItem value={icon} key={key} setter={setter} size={size} color={color} selected={selected} />;
        })}
      </Grid>
    </div>
  );
};

export default UserTeamIconList;
