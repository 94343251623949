import { HTMLAttributes } from "react";
import { FaStar, FaUserAlt } from "react-icons/fa";
import { UserType } from "../../../../utilities/redux/types";
import { Tooltip } from "../../../components/infos";
import { Shape } from "../../../components/shapes";

interface Props extends HTMLAttributes<HTMLDivElement> {
  user: Partial<UserType>;
  size?: number;
}

export const UserExpertRoleIcon = ({ user, size = 30 }: Props) => {
  return (
    <div style={{ position: "absolute", bottom: "-5px", right: "-5px" }}>
      <Tooltip legend={user?.roles?.includes("ROLE_EXPERT_OWNER") ? "Administrateur" : "Utilisateur"}>
        <Shape size={size}>
          {user?.roles?.includes("ROLE_EXPERT_OWNER") ? <FaStar size={size / 2} color="var(--color-warning)" /> : <FaUserAlt size={size / 2} color="var(--color-active)" />}
        </Shape>
      </Tooltip>
    </div>
  );
};
