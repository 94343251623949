"use client";

import { PayloadAction, Slice, createSlice } from "@reduxjs/toolkit";
import { CompanyType, QuestionExchangeType, UserType } from "utilities/redux/types";

export type QuestionExchangeSliceType = {
  questionsExchanges: Array<QuestionExchangeType & { question_not_answered_nbr: number; }>;
  questionsExchange: QuestionExchangeType & { question_nbr?: number; } | null;
  companies: CompanyType[];
  companySelected: string | null;
  accountingFirmRecipients: UserType[];
  companyRecipients: UserType[];
};

const initialState: QuestionExchangeSliceType = {
  questionsExchanges: [],
  questionsExchange: null,
  companies: [],
  companySelected: null,
  accountingFirmRecipients: null,
  companyRecipients: null
};

export const questionExchangeSlice: Slice<QuestionExchangeSliceType> = createSlice({
  name: "questionExchangesData",
  initialState,

  reducers: {
    setQuestionsExchanges: (state, action: PayloadAction<Array<QuestionExchangeType & { question_not_answered_nbr: number; }>>) => {
      state.questionsExchanges = action.payload;
    },
    setQuestionsExchange: (state, action: PayloadAction<QuestionExchangeType>) => {
      state.questionsExchange = action.payload;
    },
    setCompanyQuestionsExchanges: (state, action: PayloadAction<CompanyType[]>) => {
      state.companies = action.payload.map(company => ({
        ...company,
        questionExchanges: company?.questionExchanges?.filter(exchange => exchange.type.action === "expert_sendQuestion")
      }));
    },
    setCompanySelected: (state, action: PayloadAction<string | null>) => {
      state.companySelected = action.payload;
    },
    setCompanyRecipients: (state, action: PayloadAction<UserType[]>) => {
      state.companyRecipients = action.payload;
    },
    setAccountingFirmRecipients: (state, action: PayloadAction<UserType[]>) => {
      state.accountingFirmRecipients = action.payload;
    },
    updateReminder: (state, action: PayloadAction<QuestionExchangeType>) => {
      const exchangeIndex = state.questionsExchanges.findIndex((questionExchange) => questionExchange?.id === action.payload?.id);

      if (state?.questionsExchange?.id === action?.payload?.id) {
        state.questionsExchange = {
          ...state.questionsExchanges[exchangeIndex],
          ...action?.payload
        };
      }

      state.questionsExchanges = [
        ...state.questionsExchanges?.slice(0, exchangeIndex) ?? [],
        {
          ...state.questionsExchanges[exchangeIndex],
          ...action?.payload
        },
        ...state.questionsExchanges?.slice(exchangeIndex + 1) ?? [],
      ];
    },
  },
});

// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
  setQuestionsExchanges,
  setQuestionsExchange,
  setCompanyQuestionsExchanges,
  setCompanySelected,
  updateReminder,
  setCompanyRecipients,
  setAccountingFirmRecipients,
} = questionExchangeSlice.actions;

export default questionExchangeSlice.reducer;
