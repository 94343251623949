import { AccountType, AccountWithCategoriesType, QuestionCategoryType, QuestionGroupType, QuestionType } from "./types";

export const filterQuestion = (accounts: AccountType[], accountIndex: number, questionGroupIndex: number, questionIndex: number) => {
  let temp = JSON.parse(JSON.stringify(accounts));

  temp[accountIndex].questionGroups[questionGroupIndex].questions = temp?.[accountIndex]?.questionGroups[questionGroupIndex].questions.filter(
    (q: QuestionType, index: number) => index != questionIndex
  );

  /// CLEAN UP QUESTION GROUPS
  if (temp?.[accountIndex]?.questionGroups[questionGroupIndex].questions.length === 0) {
    let tempQuestionGroups = temp?.[accountIndex]?.questionGroups.filter((q: QuestionGroupType, index: number) => index != questionGroupIndex);

    temp[accountIndex].questionGroups = tempQuestionGroups;
  }

  /// CLEAN UP QUESTION ACCOUNTS
  if (temp?.[accountIndex]?.questionGroups.length === 0) {
    let tempAccounts = temp.filter((a: AccountType, index: number) => index != accountIndex);
    temp = tempAccounts;
  }

  if (temp?.[accountIndex]) {
    temp[accountIndex].questions_nb = temp?.[accountIndex]?.questionGroups
      .map((group: QuestionGroupType) => group?.questions?.length)
      ?.reduce((count: number, question_nb: number) => count + question_nb, 0);
  }

  return {
    questions: temp,
    count: countPreviousAccounts(temp),
  };
};

export const filterQuestionGroup = (accounts: AccountType[], accountIndex: number, questionGroupIndex: number) => {
  let temp = JSON.parse(JSON.stringify(accounts));

  let tempQuestionGroups = temp?.[accountIndex]?.questionGroups?.filter((q: QuestionGroupType, index: number) => index != questionGroupIndex);
  temp[accountIndex].questionGroups = tempQuestionGroups;

  /// CLEAN UP QUESTION ACCOUNTS
  if (temp?.[accountIndex]?.questionGroups.length === 0) {
    temp = temp.filter((a: AccountType, index: number) => index != accountIndex);
  } else {
    temp[accountIndex].questions_nb = tempQuestionGroups
      .map((group: QuestionGroupType) => group?.questions?.length)
      ?.reduce((count: number, question_nb: number) => count + question_nb, 0);
  }

  return {
    questions: temp,
    count: countPreviousAccounts(temp),
  };
};

export const filterAccount = (accounts: AccountType[], accountIndex: number) => {
  const temp = JSON.parse(JSON.stringify(accounts));

  const newAccounts = temp.filter((q: AccountType, index: number) => index != accountIndex);

  return {
    questions: newAccounts,
    questions_nb: newAccounts.questions_nb - 1,
    count: countPreviousAccounts(newAccounts),
  };
};

export function countPreviousAccounts(accounts: AccountType[]) {
  let count = accounts.length;

  return count;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FILTER IN CATEGORIES  ////////////////////////////////////////////////////////////////////////////////////

export const filterCategory = (accounts: AccountWithCategoriesType[], categoryIndex: number) => {
  let temp = JSON.parse(JSON.stringify(accounts));

  temp = temp.filter((q: AccountWithCategoriesType, index: number) => index != categoryIndex);

  return {
    questions: temp,
    count: countPreviousAccountsWithCategory(temp),
  };
};

export const filterQuestionInCategory = (
  accounts: AccountWithCategoriesType[],
  categoryIndex: number,
  accountIndex: number,
  questionGroupIndex: number,
  questionIndex: number
): {
  questions: AccountWithCategoriesType[];
  count: number;
} => {
  let temp = JSON.parse(JSON.stringify(accounts));
  let tempQuestions = temp[categoryIndex].accounts[accountIndex].questionGroups[questionGroupIndex].questions.filter(
    (q: QuestionType, index: number) => index != questionIndex
  );
  temp[categoryIndex].accounts[accountIndex].questionGroups[questionGroupIndex].questions = tempQuestions;

  /// CLEAN UP QUESTION GROUPS
  if (temp[categoryIndex].accounts[accountIndex].questionGroups[questionGroupIndex].questions.length === 0) {
    let tempQuestionGroups = temp[categoryIndex].accounts[accountIndex].questionGroups.filter(
      (q: QuestionGroupType, index: number) => index != questionGroupIndex
    );
    temp[categoryIndex].accounts[accountIndex].questionGroups = tempQuestionGroups;
  }

  temp[categoryIndex].accounts[accountIndex].questions_nb = temp[categoryIndex].accounts[accountIndex]?.questionGroups
    .map((group: QuestionGroupType) => group?.questions?.length)
    ?.reduce((count: number, length: number) => count + length, 0);

  /// CLEAN UP QUESTION ACCOUNTS
  if (temp[categoryIndex].accounts[accountIndex].questionGroups.length === 0) {
    let tempAccount = temp[categoryIndex].accounts.filter((q: AccountType, index: number) => index != accountIndex);
    temp[categoryIndex].accounts = tempAccount;
  }
  /// CLEAN UP CATEGORY
  if (temp[categoryIndex].accounts.length === 0) {
    temp = temp.filter((q: AccountWithCategoriesType, index: number) => index != categoryIndex);
  }

  return {
    questions: temp,
    count: countPreviousAccountsWithCategory(temp),
  };
};

export function countPreviousAccountsWithCategory(categories: AccountWithCategoriesType[]) {
  let count = 0;

  categories.forEach((category) => (count += category.accounts.length));

  return count;
}

///// UPDATE CATEGORIES NBR /////////////////////////////////////
export function addQuestionInCategory(categoryId: number | null, clickableCategories: QuestionCategoryType[], nbrOfQuestion: number) {
  let categoriesCopy = JSON.parse(JSON.stringify(clickableCategories));

  const categoryIndex = categoriesCopy.findIndex((category: any) => category.id === categoryId);

  if (categoryIndex !== -1) categoriesCopy[categoryIndex].question_nbr += nbrOfQuestion;

  return categoriesCopy;
}

export function substractQuestionInCategory(categoryId: number | null, clickableCategories: QuestionCategoryType[], nbrOfQuestion: number) {
  let categoriesCopy = JSON.parse(JSON.stringify(clickableCategories));

  const categoryIndex = categoriesCopy.findIndex((category: any) => category.id === categoryId);
  categoriesCopy[categoryIndex].question_nbr -= nbrOfQuestion;

  return categoriesCopy;
}

export function updateFilteredQuestion(search: string, questions: AccountType[]) {
  if (search !== "") {
    return questions.filter((item) => !item?.code?.toLowerCase().includes(search.toLowerCase()) && !item?.legend?.toLowerCase().includes(search.toLowerCase()));
  } else {
    return [];
  }
}

export function updateFilteredCategory(search: string, categoryIndex: number, categories: AccountWithCategoriesType[]) {
  if (search !== "") {
    return categories.map((category, index) => {
      if (categoryIndex === index)
        return {
          ...category,
          accounts: category.accounts.filter(
            (item) => !item?.code?.toLowerCase().includes(search.toLowerCase()) && !item?.legend?.toLowerCase().includes(search.toLowerCase())
          ),
        };
      else return category;
    });
  } else {
    return categories?.filter((item, index) => index !== categoryIndex);
  }
}
export function countFilteredQuestions(accounts: Array<AccountType>, search: string) {
  const filteredAccounts = accounts.filter(
    (item) => item?.code?.toLowerCase().includes(search.toLowerCase()) || item?.legend?.toLowerCase().includes(search.toLowerCase())
  );

  return filteredAccounts.map((account) => account?.questions_nb ?? 0)?.reduce((count, question_nb) => count + question_nb, 0);
}
