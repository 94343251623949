"use client";

import { useRouter } from "next/navigation";
import { Button } from "ui/components/buttons";
import { IconButtonIcons } from "ui/components/icons";

export default function NotFound() {
  const router = useRouter();
  return (
    <div id="error" className="page m-auto mt-5 h-100">
      <div className="tile pb-3 mw-650 " style={{ borderRadius: "40px" }}>
        <IconButtonIcons icon="stop" color="error" size={120} />
        <h1 className="text-center" style={{ fontWeight: 700, fontSize: "2rem" }}>
          La page que vous cherchez n&apos;existe pas
        </h1>
        <p className="subtitle text-center"> Cliquez sur le bouton ci-dessous pour revenir à la page principale</p>
        <Button type="dark" icon="history" cb={() => router.push("/auth/connexion")} className="mb-0 mt-0">
          Revenir sur la page d&apos;accueil
        </Button>
      </div>
    </div>
  );
}
