import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { QuestionBluePrintCategoryType } from "utilities/redux/types";

export type QuestionBluePrintType = {
  bluePrints: QuestionBluePrintType[];
  bluePrintSelected: QuestionBluePrintType;
  bluePrintCategories: QuestionBluePrintCategoryType[];
};

const initialState: QuestionBluePrintType = {
  bluePrints: [],
  bluePrintSelected: null,
  bluePrintCategories: [],
};

export const QuestionBluePrintSlice: Slice<QuestionBluePrintType> = createSlice({
  name: "questionBluePrintData",
  initialState,

  reducers: {
    setBluePrints: (state, action: PayloadAction<QuestionBluePrintType[]>) => {
      state.bluePrints = action.payload;
    },
    setBluePrintSelected: (state, action: PayloadAction<QuestionBluePrintType>) => {
      state.bluePrintSelected = action.payload;
    },
    setBluePrintCategories: (state, action: PayloadAction<QuestionBluePrintCategoryType[]>) => {
      state.bluePrintCategories = action.payload;
    },
  },
});
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setBluePrints, setBluePrintSelected, setBluePrintCategories } = QuestionBluePrintSlice.actions;

export default QuestionBluePrintSlice.reducer;
